import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import image from "../images/guides.svg"
import style from "./guides.module.css"

export default () => {
  return (
    <Layout>
      <SEO
        title="Become an online advertising expert"
        description="Learn from our easy-to-follow guides"
      />
      <div className={style.container}>
        <div className={style.textContainer}>
          <h1>Become an online advertising expert</h1>
          <img
            src={image}
            className="u-mobile-only"
            alt=""
            role="presentation"
          />

          <p>Learn from our easy-to-follow guides and:</p>
          <ul>
            <li>Master the tools to help you succeed</li>
            <li>Learn which metrics matter</li>
            <li>Improve your marketing creatives</li>
            <li>Improve your landing page</li>
            <li>Maximise your Return on Investment</li>
          </ul>

          <div>
            <p className="lead">
              <strong>Get an email when the first guide&nbsp;launches</strong>
            </p>

            <div id="mc_embed_signup">
              <form
                action="https://adlexicon.us19.list-manage.com/subscribe/post?u=7b122ecd83f041ec166e0b09a&amp;id=89dc2ea858"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className={`validate ${style.form}`}
                target="_blank"
                onSubmit={() => {
                  plausible('Guide Sign-up') // eslint-disable-line no-undef  
                }}
              >
                <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                    <label for="mce-EMAIL">
                      Email Address <small>required</small>
                    </label>
                    <input
                      type="email"
                      defaultValue=""
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                    />
                  </div>
                  <div className="mc-field-group">
                    <label for="mce-FNAME">First Name </label>
                    <input
                      type="text"
                      defaultValue=""
                      name="FNAME"
                      className=""
                      id="mce-FNAME"
                    />
                  </div>
                  <div className="mc-field-group">
                    <label for="mce-LAST_NAME">Last Name </label>
                    <input
                      type="text"
                      defaultValue=""
                      name="LAST_NAME"
                      className=""
                      id="mce-LAST_NAME"
                    />
                  </div>
                  {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <div
                    style={{ position: `absolute`, left: `-5000px` }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_7b122ecd83f041ec166e0b09a_89dc2ea858"
                      tabIndex="-1"
                      defaultValue=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <img
          src={image}
          className="u-non-mobile-only"
          alt=""
          role="presentation"
        />
      </div>
    </Layout>
  )
}
